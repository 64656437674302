import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormContext from "../FormContext";
import Input from "./Input";
import StepIndicator from "./StepIndicator";

const Step1 = ({ nextStep }) => {
  const { formData, setFormData, data } = React.useContext(FormContext);

  // Define validation schema with Yup
  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    gender: Yup.string().required("Gender is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    phoneNumber: Yup.string()
      .matches(/^\d{11}$/, "Phone number should be 11 digits")
      .required("Phone number is required"),
    education: Yup.string().required("Education Qualification is required"),
    location: Yup.string().required("Location is required"),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      setFormData(values);
      nextStep(); // Move to the next step
    },
  });

  // Handle the next button click
  const handleNextClick = async (event) => {
    event.preventDefault(); // Prevent default form submission
  
    // Trigger validation
    const errors = await formik.validateForm();
    formik.setTouched({
      firstName: true,
      lastName: true,
      gender: true,
      email: true,
      phoneNumber: true,
      education: true,
      location: true,
    });

    // Check if there are any validation errors
    if (Object.keys(errors).length === 0) {
      formik.handleSubmit(); // If no errors, submit the form
    } else {
      formik.setErrors(errors); // Set form errors if there are any
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className="step-1-container">
      <div className="top">
        <StepIndicator currentStep={0} />
      </div>
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-secondary-color font-nueu text-xl">
          Personal Information
        </h1>
        <p className="text-sm text-slate-700">
          Please enter your personal information
        </p>
      </div>
      <div className="form-content flex flex-col gap-5 mt-4 w-11/12">
        <div className="flex flex-col md:flex-row justify-between gap-4 md:gap-8 w-full">
          <Input
            label="First Name"
            type="text"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && formik.errors.firstName}
          />
          <Input
            label="Last Name"
            type="text"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && formik.errors.lastName}
          />
        </div>
        <div className="flex flex-col md:flex-row justify-between gap-4 md:gap-8 w-full">
          <Input
            label="Gender"
            type="select"
            name="gender"
            value={formik.values.gender}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.gender && formik.errors.gender}
            options={
              data && data.genders
                ? data.genders.map((item) => ({
                    value: item.code,
                    label: item.description,
                  }))
                : []
            }
          />
          <Input
            label="Email"
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && formik.errors.email}
          />
        </div>
        <div className="flex flex-col md:flex-row justify-between gap-4 md:gap-8 w-full">
          <Input
            label="Phone Number"
            type="tel"
            name="phoneNumber"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phoneNumber && formik.errors.phoneNumber}
          />
          <Input
            label="Education Qualification"
            type="select"
            name="education"
            value={formik.values.education}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.education && formik.errors.education}
            options={
              data && data.educationQualification
                ? data.educationQualification.map((item) => ({
                    value: item.code,
                    label: item.description,
                  }))
                : []
            }
          />
        </div>
        <div className="flex flex-col md:flex-row justify-between gap-4 md:gap-8 w-full">
          <Input
            label="Location"
            type="textarea"
            name="location"
            value={formik.values.location}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.location && formik.errors.location}
          />
        </div>
      </div>

      <div className="button-container">
        {/* {formik.errors && <p className="error-message">{Object.values(formik.errors).join(", ")}</p>} */}
        <button type="button" onClick={handleNextClick}  className="next-btn font-nueu ">
          Next
        </button>
      </div>
    </form>
  );
};

export default Step1;
