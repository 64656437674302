import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormContext from "../FormContext";
import Input from "./Input";
import StepIndicator from "./StepIndicator";
import { baseUrl } from "../baseUrl";
import axios from "axios";
import { debounce } from "lodash";
import Modal from "./Modal";
import Submit from "./Submit";

const Step3 = ({ nextStep, prevStep }) => {
  const { formData, setFormData, data } = useContext(FormContext);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [promoCodeValid, setPromoCodeValid] = useState(false);
  const [promoCodeError, setPromoCodeError] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [postResponse, setPostResponse] = useState(null);
  const [isValid, setIsValid] = useState(false);

  // Validation schema
  const validationSchema = Yup.object({
    aspirations: Yup.string().max(250, "Must be 250 characters or less").required("Aspirations is required"),
    growth: Yup.string().max(250, "Must be 250 characters or less").required("Growth is required"),
    expectations: Yup.string().max(250, "Must be 250 characters or less").required("Expectations are required"),
    hearAboutUs: Yup.string().required("How did you hear about us is required"),
    hearAboutUsOthers: Yup.string().when('hearAboutUs', {
      is: '10',
      then: Yup.string().required("Please specify how you heard about us"),
    }),
    promoApplied: Yup.string()
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      const sessionId = localStorage.getItem("sessionId");
      if (isValid === false && (values.hearAboutUs === "1" || values.hearAboutUs === "2" || values.hearAboutUs === "3" || values.hearAboutUs === "4")) {
        setError("Please input the right promo code or clear out the invalid code.");
        return;
      }
      setSubmitLoading(true);
      const postUrl = `${baseUrl}Registration/SaveRegistration`;
      try {
        const response = await axios.post(postUrl, { ...values, session: sessionId }, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        setSubmitLoading(false);
        if (response.data.status) {
          console.log(response.data);
          setPostResponse(response.data);
          setShowConfirmationModal(false); // Close the confirmation modal
          setShowSuccessModal(true);
          // clear form data
          setFormData({})
        }
      } catch (error) {
        setSubmitLoading(false);
        let errorMessage = "Something went wrong";
        if (error.response) {
          switch (error.response.status) {
            case 404:
              errorMessage = "The form submission service seems to be unavailable at the moment. Please try again later.";
              break;
            case 500:
              errorMessage = "We're currently experiencing server issues. Your form submission could not be processed at this time. Please try again later.";
              break;
            default:
              errorMessage = error.response.data;
          }
        }
        setError(errorMessage);
      }
    },
  });

  const debouncedValidation = React.useMemo(() => debounce((promoCode) => {
    promoCode = promoCode.trim();
    if (promoCode === "") {
      setIsValid(null);
      setPromoCodeValid(false);
      setPromoCodeError("");
      return;
    }
    setLoading(true);
    const url = `${baseUrl}Registration/CheckPromoCode/${formData.session}/${promoCode}`;
    axios
      .get(url)
      .then((response) => {
        setLoading(false);
        if (response.data.status && response.data.data.slotAvailable) {
          setIsValid(true);
          setPromoCodeValid(true);
          setPromoCodeError("");
        } else {
          setIsValid(false);
          setPromoCodeValid(false);
          setPromoCodeError(response.data.message || "Invalid promo code");
        }
      })
      .catch((error) => {
        setLoading(false);
        setIsValid(null);
        setPromoCodeValid(false);
        setPromoCodeError("Validation failed for applied promo code");
      });
  }, 500), [formData.session, setIsValid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    formik.handleChange(e);
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    if (name === "hearAboutUs") {
      setFormData((prevState) => ({ ...prevState, promoApplied: "" }));
    } else if (name === "promoApplied") {
      debouncedValidation(value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowConfirmationModal(true);
  };

  const handleConfirmSubmit = () => {
    formik.handleSubmit();
  };

  const handleCancelSubmit = () => {
    setShowConfirmationModal(false);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    // You can optionally redirect here or perform other actions
  };

  return (
    <>
      {
        !showSuccessModal ? (
          <>

            <form className="step-1-container" onSubmit={handleSubmit}>
              <div className="top">
                <StepIndicator currentStep={2} />
              </div>
              <div className="flex flex-col justify-center items-center">
                <h1 className="text-secondary-color font-nueu text-xl">General Information</h1>
                <p className="text-sm text-slate-700">Please fill in all fields</p>
              </div>
              <div className="form-content flex flex-col gap-5 mt-4 w-11/12">
                {/* Form Fields */}
                <Input
                  label="What future aspirations do you have for your business? (Max 250 words)"
                  type="textarea"
                  name="aspirations"
                  value={formik.values.aspirations}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.aspirations && formik.errors.aspirations}
                />
                <Input
                  label="What’s stopping your business from being 10 times what it currently is? (Max 250 words)"
                  type="textarea"
                  name="growth"
                  value={formik.values.growth}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.growth && formik.errors.growth}
                />
                <Input
                  label="What are your expectations from this program? (Max 250 words)"
                  type="textarea"
                  name="expectations"
                  value={formik.values.expectations}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.expectations && formik.errors.expectations}
                />
                <Input
                  label="How did you hear about us"
                  type="select"
                  name="hearAboutUs"
                  value={formik.values.hearAboutUs}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.hearAboutUs && formik.errors.hearAboutUs}
                  options={
                    data && data.hearAboutUs
                      ? data.hearAboutUs.map((item) => ({
                        value: item.code,
                        label: item.description,
                      }))
                      : []
                  }
                />
                {formik.values.hearAboutUs === "10" && (
                  <Input
                    label="Please specify"
                    type="text"
                    name="hearAboutUsOthers"
                    value={formik.values.hearAboutUsOthers}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.hearAboutUsOthers && formik.errors.hearAboutUsOthers}
                  />
                )}
                <Input
                  label="Promo Code or Referral Code (if any)"
                  type="text"
                  name="promoApplied"
                  value={formik.values.promoApplied}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.promoApplied && formik.errors.promoApplied}
                />
                {loading && <p className="text-sm text-slate-700">Validating Promo Code...</p>}
                {promoCodeValid && <p className="text-sm text-green-500">Promo Code Applied</p>}
                {promoCodeError && <p className="text-sm text-red-500">{promoCodeError}</p>}
              </div>
              {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
              <div className="button-container mt-4">
                <div className="flex gap-4 w-full">
                  <button type="button" onClick={prevStep} className="prev-btn font-nueu">
                    Prev
                  </button>
                  <button type="submit" disabled={formik.isSubmitting || submitLoading} className="next-btn font-nueu">
                    Submit & Pay
                  </button>
                </div>
              </div>
            </form>

            {/* Confirmation Modal */}
            {showConfirmationModal && (
              <Modal
                title="Confirm Submission"
                onConfirm={handleConfirmSubmit}
                onCancel={handleCancelSubmit}
                confirmText="Yes, Submit"
                cancelText="Cancel"
                loading={submitLoading}
              >
                <p>Please confirm you would like to submit.</p>
              </Modal>
            )}
          </>
        ) : (

          <Submit
            response={postResponse}
            onClose={handleCloseSuccessModal}
          />

        )
      }

    </>
  );
};

export default Step3;