import React, {useEffect} from "react";
const Submit = ({response}) => {

  useEffect(() => {
    // set timeout to redirect to home page after 1 hour
    setTimeout(() => {
      window.location.href = "/";
    }, 3600000);

    return () => {
      clearTimeout();
    }
  }

  , []);

  return (
    <div className="step-1-submit">
      {/*  form has been submitted successfully */}
      <div className="submit-content flex flex-col justify-center items-center">
        <svg
          viewBox="0 0 26 26"
          xmlns="http://www.w3.org/2000/svg"
          className="checkmark"
        >
          <g
            stroke="currentColor"
            stroke-width="2"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              className="circle"
              d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"
            />
            <path className="tick" d="M6.5 13.5L10 17 l8.808621-8.308621" />
          </g>
        </svg>
        <h1 className="text-secondary-color font-nueu text-2xl mt-4 md:mt-2 md:text-xl">
          Successful
        </h1>
        <p className="text-base md:text-sm text-slate-700">
          Your response has been submitted successfully
        </p>
        <br />
        <p className="text-base md:text-base font-bold text-slate-700">
          Payment Details will be sent to your email or you can
          {response?.data?.paymentLink && (
            <a href={response.data.paymentLink} target="_blank" rel="noreferrer"
              className="text-primary-color hover:text-gray-900"
            >
              {" "}
              click here
            </a>
          )}{" "}
          to make payment
        </p>
      </div>
    </div>
  );
};



export default Submit