import React, { useContext } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import img1 from "../assets/img-1.jpg";
import img2 from "../assets/img-2.jpg";
import img3 from "../assets/img-3.jpg";
import img4 from "../assets/img13.jpg";
import { FaCalendarAlt } from "react-icons/fa";
import { BsFillClockFill, BsPinMap } from "react-icons/bs";
import { BiSolidWebcam } from "react-icons/bi";
import FormContext from "../FormContext";
import moment from "moment";
import { Link } from "react-router-dom";
import CountdownTimer from "../components/CountDownTimer";
// import sessionImage from "../assets/seesionImg.jpg"
const Sessions = () => {
  const { sessions, formData, setFormData } = useContext(FormContext);
  const shuffleImages = [img1, img2, img3, img4];

  const handleSessionSelect = (session) => {
    // set the sessionId on localstorage 
    localStorage.setItem("sessionId", session.sessionId);
    setFormData({
      ...formData,
      session: session.sessionId,
    });
  };


  return (
    <div className="w-full min-h-screen bg-slate-100">
      <Navbar />

      {/* event banner here */}
      <div
        className="w-full h-screen flex flex-col gap-2 justify-center items-center  relative bg-cover bg-center bg-no-repeat bg-fixed bg-gradient-to-r from-primary-yellow to-primary-color"
        style={{
          backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-black bg-opacity-50 w-full h-full absolute top-0 left-0"></div>
        <div className="flex flex-col gap-4 items-center justify-center z-10 text-white text-center px-4 py-8 md:py-16">
          <h1 className="text-4xl md:text-6xl  text-white font-bold text-center">
            A Journey To <br />
            <span className="text-primary-yellow text-4xl md:text-8xl font-extrabold  ">
              Self Discovery
            </span>
          </h1>
          <p className="text-white text-center text-xl">
            {" "}
            Register for the upcoming session
          </p>

          {/*  register for virtual or onsite with just the price tags */}

          <CountdownTimer sessionDate={sessions[0]?.date} />
        </div>
      </div>

      {
        sessions.length === 0 ? (
          <div className="h-[80vh] flex flex-col justify-center items-center">
            <div className="flex flex-col gap-4 w-11/12 mx-auto items-center justify-center">
              <svg width="221" height="228" viewBox="0 0 221 228" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.6631 100.029C23.6631 100.029 62.434 98.4767 70.8125 34.7456C78.2528 -21.8537 143.631 3.90825 162.529 26.4701C187.01 55.6971 173.96 105.974 205.629 112.325C237.299 118.676 216.618 189.292 168.546 182.533C108.746 174.125 123.214 209.069 105.262 223.859C92.3786 234.475 39.5515 224.256 37.7908 185.723C36.3092 153.297 22.6315 153.4 12.9366 149.723C-1.04559 144.421 -9.85593 106.017 23.6631 100.029Z" fill="#FFDA93" fill-opacity="0.49" />
                <path d="M155.462 170.807L117.371 133.168L109.828 140.801L147.92 178.44L155.462 170.807Z" fill="#E99900" fill-opacity="0.49" />
                <path d="M170.013 180.92L140.378 151.637C140.213 151.474 139.947 151.476 139.784 151.641L128.568 162.992C128.405 163.157 128.406 163.423 128.571 163.586L158.206 192.869C158.371 193.032 158.637 193.031 158.8 192.866L170.017 181.514C170.18 181.349 170.178 181.083 170.013 180.92Z" fill="#E99900" />
                <path d="M83.6107 152.097C109.816 152.097 131.06 130.853 131.06 104.648C131.06 78.4421 109.816 57.1982 83.6107 57.1982C57.4051 57.1982 36.1613 78.4421 36.1613 104.648C36.1613 130.853 57.4051 152.097 83.6107 152.097Z" fill="#E99900" />
                <path d="M83.6108 144.355C105.54 144.355 123.318 126.578 123.318 104.648C123.318 82.7183 105.54 64.9407 83.6108 64.9407C61.6811 64.9407 43.9036 82.7183 43.9036 104.648C43.9036 126.578 61.6811 144.355 83.6108 144.355Z" fill="#FFE2AA" />
                <path d="M163.83 61.7531C152.7 58.1454 140.672 64.247 137.049 75.4228C133.442 86.5528 139.543 98.5809 150.719 102.203C161.895 105.825 173.892 99.664 177.5 88.5334C181.108 77.4028 174.96 65.3606 163.83 61.7531ZM160.471 72.1145C161.717 72.5183 162.815 73.283 163.626 74.3117C164.436 75.3403 164.923 76.5865 165.024 77.8922C165.126 79.1979 164.837 80.5043 164.195 81.6456C163.552 82.787 162.586 83.7119 161.417 84.3031C160.249 84.8943 158.931 85.1251 157.631 84.9662C156.331 84.8074 155.107 84.266 154.115 83.4108C153.124 82.5556 152.408 81.4251 152.06 80.1627C151.711 78.9003 151.746 77.5628 152.158 76.3199C153.273 72.8813 157.032 70.9999 160.471 72.1145ZM152.068 98.0405C148.37 96.8481 145.202 94.4072 143.106 91.1359C147.029 88.9072 151.13 88.2864 154.84 89.489C158.55 90.6916 161.567 93.5693 163.377 97.7058C159.76 99.1257 155.762 99.244 152.068 98.0405Z" fill="#E99900" />
                <path d="M71.1912 163.176C69.258 164.163 67.6608 165.701 66.6016 167.595C65.5424 169.49 65.0688 171.656 65.2406 173.82C65.4125 175.983 66.2221 178.047 67.5671 179.751C68.9121 181.455 70.7321 182.721 72.7968 183.39C74.8616 184.06 77.0785 184.101 79.167 183.511C81.2556 182.92 83.1222 181.723 84.5306 180.072C85.939 178.42 86.8261 176.388 87.0797 174.233C87.3332 172.077 86.9418 169.895 85.955 167.961C84.6294 165.371 82.3305 163.413 79.5627 162.516C76.7948 161.618 73.7841 161.856 71.1912 163.176ZM73.7473 168.184C74.3494 167.876 75.0293 167.755 75.7006 167.834C76.3719 167.914 77.0045 168.191 77.5181 168.63C78.0317 169.07 78.4032 169.652 78.5854 170.303C78.7676 170.954 78.7523 171.645 78.5415 172.287C78.3307 172.93 77.9339 173.495 77.4013 173.911C76.8688 174.328 76.2246 174.577 75.5504 174.626C74.8762 174.676 74.2024 174.525 73.6145 174.191C73.0265 173.857 72.5509 173.356 72.2481 172.752C71.8466 171.946 71.7789 171.015 72.0595 170.16C72.3402 169.305 72.9466 168.594 73.7473 168.183V168.184ZM80.1429 180.714C78.3581 181.628 76.3108 181.893 74.3518 181.464C74.9699 179.219 76.2403 177.496 78.0338 176.581C79.8274 175.665 81.9785 175.615 84.1486 176.464C83.3467 178.302 81.9306 179.804 80.1429 180.713V180.714Z" fill="#E99900" />
              </svg>


              <h2 className="text-2xl font-semibold mt-8 text-secondary-color font-nueu ">
                No Available Sessions
              </h2>
              <p className="text-secondary-color text-center">
                There are currently no available sessions. Please check back later.
              </p>
            </div>
          </div>
        ) : (

          <div className="flex flex-col gap-4 w-11/12 mx-auto ">
            <h2 className="text-2xl font-semibold mt-8 text-secondary-color font-nueu ">
              Available Sessions
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3  lg:grid-cols-4  w-full justify-center items-center gap-5 mb-4 grid-auto-rows-minmax(0, auto)">
              {sessions?.map((session, index) => (
                <div
                  key={session.sessionId}
                  className="card-container bg-white shadow-sm rounded-lg overflow-hidden relative w-full h-auto  transition-all duration-300 ease-in-out"
                  style={{
                    pointerEvents: session.availableSlots > 0 ? "auto" : "none",
                    opacity: session.availableSlots > 0 ? 1 : 0.5,
                  }}
                >
                  {session?.soldOut && (
                    <div className="absolute top-0 left-0 z-[999] bg-[#EF4B32] text-white px-4 py-2">
                      Sold Out
                    </div>
                  )}
                  {session?.almostSoldOut && (
                    <div className="absolute top-0 left-0 bg-primary-yellow text-white px-3 py-2">
                      Almost Sold Out
                    </div>
                  )}

                  {/*  display price tags at the top right corner */}
                  <div className="absolute top-60 right-0 bg-[#EF4B32] text-white px-3 py-2">
                    {session.isVirtual ? (
                      <div className="flex items-center gap-2 text-sm font-bold">
                        <BiSolidWebcam className="" />
                        <p className="">Virtual</p>
                      </div>
                    ) : (
                      <div className="flex items-center gap-2 text-sm font-bold">
                        <BsPinMap className="" />
                        <p className="">Physical</p>
                      </div>
                    )}
                  </div>

                  <div className="">
                    <img
                      src={
                        session.imageURL
                          ? session.imageURL
                          : shuffleImages[index % 4]
                      }
                      alt=""
                      className="w-full h-auto max-h-60 object-cover object-top"
                    />
                    <div className="px-2 py-6 mt-4">
                      <div className="flex justify-between items-center">
                        <h1 className="text-2xl font-bold text-primary-color font-nueu">
                          {session?.title}
                        </h1>
                      </div>
                      <p className="text-secondary-color text-sm mt-2">
                        {session?.description}
                      </p>
                      <p className="mt-2 text-red-600 font-semibold">
                        Price:
                        {session?.sessionDefaultPromo ? (
                          <>
                            <del
                              className="text-gray-400 mx-2 font-light line-through text-sm"
                            >&#8358;{session?.cost.toLocaleString()}</del>
                            &#8358;
                            {session?.sessionDefaultPromo.amount.toLocaleString()}
                          </>
                        ) : (
                          <span className="mx-2">&#8358;{session?.cost.toLocaleString()}</span>
                        )}
                      </p>

                      <div className="flex items-center mt-4">
                        <FaCalendarAlt className="text-primary-color" />
                        <p className="text-secondary-color ml-2">
                          {moment(session?.date).format("MMM Do YYYY")}
                        </p>
                      </div>
                      <div className="flex items-center mt-4">
                        <BsFillClockFill className="text-primary-color" />
                        <p className="text-secondary-color ml-2">
                          {moment(session?.date).format("HH:mm A")}
                        </p>
                      </div>
                    </div>
                    {
                      // display the overlay only if the session is not sold out
                      session.availableSlots > 0 ? (
                        <div className="overlay">
                          <Link
                            to={`/session/${session?.sessionId}`}
                            onClick={() => handleSessionSelect(session)}
                          >
                            <button className="bg-primary-color text-white px-4 py-2 rounded-lg">
                              Book Now
                            </button>
                          </Link>
                        </div>
                      ) : (
                        <div className="overlay">
                          <button
                            className="bg-red-500 text-white px-4 py-2 rounded-lg"
                            disabled
                          >
                            Sold Out
                          </button>
                        </div>
                      )
                    }
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      }

      <Footer />
    </div>
  );
};

export default Sessions;
